@import '@agentguru/foundations/dist/scss/main.scss';

.container {
  @include background-color(neutral, white);
  @include padding-left(md);
  @include padding-right(lg);
  @include border-bottom(neutral, gray-3);

  display: flex;
  align-items: center;
  height: 5.5rem;
  width: 100%;

  > svg {
    height: 1.0625rem;
  }
}
